// import moment, { Moment } from "moment";
// import { Event } from "microsoft-graph";
// import {
//   GraphRequestOptions,
//   PageCollection,
//   PageIterator
// } from "@microsoft/microsoft-graph-client";

var graph = require("@microsoft/microsoft-graph-client");

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: done => {
      done(null, accessToken);
    }
  });

  return client;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client
    .api("/me")
    .select("displayName,mail,userPrincipalName, id")
    .get();

  return user;
}

export async function getUserGroups(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  const required_group_id = "608c1ede-9308-46b3-8b62-f0d2142ae53a";

  const groups = await client
    .api("/me/memberOf")
    .filter(`id eq '${required_group_id}'`)
    .get();

  return groups.value;
}
