import React, { useState, useEffect, createContext } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "../../Config";
import { getUserDetails, getUserGroups } from "../../services/GraphService";

export const AuthProviderContext = createContext();
export const AuthProvider = props => {
  const [authProviderState, setAuthProviderState] = useState({
    error: null,
    isAuthenticated: false,
    user: {}
  });
  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority:
        "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/",
      redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  });

  const handdleLogin = async () => {
    await publicClientApplication
      .handleRedirectPromise()
      .then(handleResponse)
      .catch(err => {
        setAuthProviderState({
          isAuthenticated: false,
          user: {},
          error: normalizeError(err)
        });
      });
  };

  const handleResponse = response => {
    if (response && response.account !== undefined) {
      // Enhance user object with data from Graph
      getUserProfile();
    } else {
      login();
    }
  };

  const login = async () => {
    try {
      // Login via redirect
      await publicClientApplication.loginRedirect({
        scopes: config.scopes,
        prompt: "select_account"
      });

      // Login via popup
      // await publicClientApplication.loginPopup({
      //   scopes: config.scopes,
      //   prompt: "select_account"
      // });

      // After login, get the user's profile
      await getUserProfile();

      // After get profile, get the user authorization in specific group
      await getUserAuthorization();
    } catch (err) {
      setAuthProviderState({
        isAuthenticated: false,
        user: {},
        error: normalizeError(err)
      });
    }
  };

  const logout = async () => {
    await publicClientApplication.handleRedirectPromise();
    await publicClientApplication.logout();
  };

  const getAccessToken = async scopes => {
    try {
      const accounts = publicClientApplication.getAllAccounts();

      if (accounts.length <= 0) throw new Error("login_required");
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      var silentResult = await publicClientApplication.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0]
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (isInteractionRequired(err)) {
        var interactiveResult = await publicClientApplication.acquireTokenPopup(
          {
            scopes: scopes
          }
        );

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    }
  };

  const getUserProfile = async () => {
    try {
      var accessToken = await getAccessToken(config.scopes);

      if (accessToken) {
        // Get the user's profile from Graph
        var user = await getUserDetails(accessToken);
        setAuthProviderState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            id: user.id
          },
          error: null
        });
      }
    } catch (err) {
      setAuthProviderState({
        isAuthenticated: false,
        user: {},
        error: normalizeError(err)
      });
    }
  };

  const getUserAuthorization = async () => {
    var accessToken = await getAccessToken(config.scopes);
    try {
      if (accessToken) {
        // Get the user's profile from Graph
        var group = await getUserGroups(accessToken);
        if (!group.value.length) {
          throw new Error("Unauthorization.");
        }
      }
    } catch (err) {
      setAuthProviderState({
        isAuthenticated: false,
        user: {},
        error: normalizeError(err)
      });
    }
  };

  const setErrorMessage = (message, debug) => {
    setAuthProviderState({
      error: { message: message, debug: debug }
    });
  };

  const normalizeError = error => {
    var normalizedError = {};
    if (typeof error === "string") {
      var errParts = error.split("|");
      normalizedError =
        errParts.length > 1
          ? { message: errParts[1], debug: errParts[0] }
          : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error)
      };
    }
    return normalizedError;
  };

  const isInteractionRequired = error => {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf("consent_required") > -1 ||
      error.message.indexOf("interaction_required") > -1 ||
      error.message.indexOf("login_required") > -1 ||
      error.message.indexOf("no_account_in_silent_request") > -1
    );
  };
  useEffect(() => {
    handdleLogin();
  }, []);

  return (
    <AuthProviderContext.Provider
      value={{
        error: authProviderState.error,
        isAuthenticated: authProviderState.isAuthenticated,
        user: authProviderState.user,
        login: async () => await handdleLogin(),
        logout: async () => await logout(),
        getAccessToken: scopes => getAccessToken(scopes),
        setError: (message, debug) => setErrorMessage(message, debug)
      }}
    >
      {props.children}
    </AuthProviderContext.Provider>
  );
};
