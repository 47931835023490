export const config = {
  appId: "8994d7fd-4641-4289-b6ea-80f8e5969a5c",
  redirectUri: "http://localhost:3000",
  authority: "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/",
  scopes: ["User.Read"]
};

// authority: "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/",
// authority: "https://login.microsoftonline.com/organizations/",
// redirectUri: "http://localhost:3000",



 