import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import OnLoading from "./components/OnLoading";
import { AuthProviderContext } from "./context/AuthProvider";

const HomePage = React.lazy(() => import("./pages"));

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, login } = useContext(AuthProviderContext);
  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated ? children : login())}
    />
  );
}

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Suspense fallback={<OnLoading isLoading={1} />}>
            <PrivateRoute to="/" component={HomePage} exact />
            {/* <Route to="/" component={HomePage} exact /> */}
          </Suspense>
        </Switch>
      </Router>
    </>
  );
}

export default App;
