import React from "react";
import { Container, Ring, InnerRing } from "./OnLoadingElements";

const OnLoading = ({ isLoading }) => {
  return (
    <>
      <Container show={isLoading}>
        <Ring>
          <InnerRing />
        </Ring>
      </Container>
    </>
  );
};

export default OnLoading;
